<!-- 基础资料 -->
<template>
  <div style="margin-top:10px">
    <!-- 标题 -->
    <title-name-slot title="基础资料">
      <template slot="operation">
        <div class="makeTitle">
            <base-button
            label="批量下载基础资料"
            style="float: right; margin-left: 10px"
            @click.prevent="downAll()"
            icon="iconfont iconxiazai"
          />
          <base-button
            label="增加文件类型"
            style="float: right; margin-left: 10px"
            @click.prevent="addFileType"
            icon="iconfont icona-wenjian2"
          />
        </div>
      </template>
    </title-name-slot>
    <!-- 合并表格 -->
    <base-table
      :columns="columns"
      :showPage="false"
      :tableAttrs="{
        data: tableData,
        stripe: false,
        spanMethod: objectSpanMethod
      }"
      :isCaculateHeight="false"
      ref="tableData"
      :webPage="true"
      style="margin-top: 10px"
    >
      <template slot="index" slot-scope="scope">{{
        scope.$index + 1
      }}</template>
      <template slot="documentName" slot-scope="scope">
        <div style="overflow: hidden;">
          <div class="addBtn">
            <div class="bg" @click="addDocument(scope.row, scope.$index)">
              <i class="el-icon el-icon-plus"></i>
            </div>
          </div>
          <template v-if="scope.row.addType">
            <el-select
                v-model="tableData[scope.$index].documentId"
                @change="
                  (val) => changeDocument(val, scope.row, scope.$index)
                "
                placeholder="请选择"
              >
                <el-option
                  v-for="item in scope.row.option"
                  :key="item.keyId"
                  :label="item.documentName"
                  :value="item.documentId"
                />
            </el-select>
          </template>
          <template v-else>{{ scope.row.documentName }}</template>
        </div>
      </template>
      <template slot="signName" slot-scope="scope">
        <!-- 基础资料提供企业 -->
        <template v-if="scope.row.fileListDetailVOS.length === 0 || (scope.row.fileListDetailVOS.length !== 0 && !scope.row.fileListDetailVOS[0].fileId)">
          <NewBaseSelect
            v-model="scope.row.signerId"
            @change="(val) => changeGys(val, scope.row, scope.$index)"
            placeholder="请选择"
            :options="gysData"
            filterable
            :selectedField="['keyId', 'enterpriseName']"
          ></NewBaseSelect>
        </template>
        <template v-else>{{ scope.row.signName }}</template>
      </template>
      <template slot="fileList" slot-scope="scope">
        <div style="display: flex;align-items: center;">
          <div style="flex: 1;width: calc(100% - 30px);">
            <!-- 文件列按钮组 -->
            <file-button-group
              v-for="(item, index) in scope.row.fileListDetailVOS"
              :key="index"
              :item="item"
              :index="index"
              :scope="scope"
              :noUpload="true"
              :showEditBtn="false"
              :showTag="type === 0 || type === 1"
              @previewShow="previewShow"
              @deleteTemplate="deleteTemplate"
              @dowload='dowload'
            />
          </div>

          <!-- 选择文件 -->
          <icon-button
          @click="selectFile(scope.$index, scope.row.documentId, scope.row.signerId)"
            content='选择'
            icon="iconfont iconxuanze"
          />
        </div>

      </template>
      <!-- 是否需要盖章 -->
      <template slot="isSign" slot-scope="scope">
        <div v-for="(item, index) in scope.row.fileListDetailVOS" :key="index" class="isSign">
          <el-checkbox
            v-model="item.isSign"
            :true-label="'1'"
            :false-label="'0'"
            @change="changeIsSign(scope.row,scope.$index,item,index)"
            ></el-checkbox>
        </div>
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 操作按钮组 -->
        <contaract-button-group
          :scope="scope"
          :produceBtn="false"
          :isAdd="false"
          @previewShow="previewShow"
          @deleteTemplate="deleteTemplate"
          @viewResume='viewResume'
        />
      </template>
    </base-table>
    <!-- 选择企业文件弹窗 -->
    <SelectBusinessInfoDialog :visible.sync="visible" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" :showSigned="true" :typeOption="fileTypeList" :showIndex="showIndex" @selectFileList="selectFileList" :enterpriseId="enterpriseId" :documentId="documentId" :fileDetailList="fileListDetailVOS"></SelectBusinessInfoDialog>
  </div>
</template>
<script>
import titleNameSlot from '@/pages/dashboard/components/title-name-slot.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import FileButtonGroup from './file-button-group.vue'
import ContaractButtonGroup from './contaract-button-group.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import NewBaseSelect from '@/components/common/base-select/new-base-select'
import SelectBusinessInfoDialog from '@/pages/business/financing-decision-manage/components/select-business-info-dialog.vue'
import {
  // uploadcontractApi,
  // fileAPi,
  downAPi
} from '@/api/fileApi'
import { fileListApi, replenishApi } from '@/api/businessApi'
import { downFile } from '@/utils/devUtils'
import { baseColumns } from '../utils/config'
import { supplierApi } from '@/api/companyApi'
import Storage from '@/utils/storage'

export default {
  props: {
    dataList: Array,
    businessId: String,
    type: { // 用户判断是原始调用还是补签调用 ，非必填
      type: Number,
      default: 0 // 0 原始， 1 补签
    }
  },
  data () {
    return {
      tableData: [],
      gysData: [],
      fileTypeList: [], // 文件类型数据
      // 全选内容
      checkAll: false,
      checkedCitiesL: [],
      isIndeterminate: false,
      showIndex: 0,
      visible: false,
      enterpriseId: '',
      documentId: '',
      fileListDetailVOS: [],
      options: []
    }
  },
  components: {
    titleNameSlot,
    BaseTable,
    FileButtonGroup,
    ContaractButtonGroup,
    BaseButton,
    NewBaseSelect,
    IconButton,
    SelectBusinessInfoDialog
  },
  computed: {
    columns () {
      return baseColumns(this)
    }
  },
  watch: {
    dataList: {
      handler (val) {
        this.$nextTick(() => {
          this.gysList()
          this.tableData = JSON.parse(JSON.stringify(val))
          this.tableData = this.filterData(
            this.tableData,
            'documentName',
            'documentNameRowSpan'
          )
          this.getChecked()
        })
      },
      deep: true
    }
  },
  created () {
    this.getsupFileList()
  },
  methods: {
    // 获取供应商文件类型
    getsupFileList () {
      supplierApi.getsupFileList({ configSetCode: 'F_GYS_BASEFILE' })
        .then((res) => {
          if (res.data) {
            console.log(res.data, '文件类型')
            this.fileTypeList = res.data.map(ele => {
              return { id: ele.configItemCode, name: ele.configItemName }
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        console.log(res, '供应商数据')
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      if (
        column.property === 'documentName' && row.documentName
      ) {
        const _row = row.documentNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 合并数据
    filterData (arr = [], innerKey, orderKey) {
      const res = []
      const map = {}
      arr.forEach((item) => {
        const key = item[innerKey]
        item[orderKey] = 0
        if (!map[key]) {
          map[key] = [item]
        } else {
          map[key].push(item)
        }
      })
      for (const key in map) {
        const list = map[key]
        list[0][orderKey] = list.length
        res.push(...list)
      }
      return res
    },
    // 添加列
    addDocument (row, index) {
      if (row.documentId) {
        this.tableData.forEach((ele, i) => {
          if (ele.documentId === row.documentId) {
            index = i
          }
        })
        this.tableData.splice(index + 1, 0, { documentName: row.documentName, documentId: row.documentId, documentType: row.documentType, addFile: true, fileListDetailVOS: [] })
        this.tableData = this.filterData(
          this.tableData,
          'documentName',
          'documentNameRowSpan'
        )
      } else {
        this.warning('请先选择文件类型名称')
      }
    },
    // 选取合同文件-材料题名
    changeDocument (item, data, index) {
      let state = true
      this.tableData.forEach((ele, i) => {
        if (i !== index) {
          if (ele.documentIds === item || ele.documentId === item) {
            state = false
          } else {
            this.$set(ele, 'documentIds', ele.documentId)
          }
        }
      })
      if (!state) {
        this.$set(data, 'documentId', '')
        this.$set(data, 'documentIds', '')
        return this.warning('文件类型不能相同')
      } else {
        const params = {
          businessId: this.$route.query.businessId,
          documentId: data.documentId
        }
        fileListApi.getTemplate(params).then(res => {
          this.$set(data, 'documentIds', item)
          const [obj] = this.options.filter((it) => it.documentId === item)
          this.$set(data, 'documentName', obj.documentName)
          this.$set(data, 'documentType', obj.documentType)
          this.$set(data, 'isAdd', true)
          this.$set(data, 'templateFileId', res.data.templateFileId)
          this.$set(data, 'isGenerate', obj.isGenerate)
          this.$set(data, 'isSign', obj.isSign)
          this.tableData = this.filterData(
            this.tableData,
            'documentName',
            'documentNameRowSpan'
          )
        })
      }
    },
    // 选取基础资料提供企业
    changeGys (item, data, index) {
      let state = true
      this.tableData.forEach((ele, i) => {
        if (i !== index) {
          if ((ele.signerIds === item || ele.signerId === item) && this.tableData[index].documentId === ele.documentId) {
            state = false
          } else {
            this.$set(ele, 'signerIds', ele.signerId)
          }
        }
      })
      if (!state) {
        this.$set(data, 'signerId', '')
        this.$set(data, 'signerIds', '')
        return this.warning('基础资料提供企业不能相同')
      } else {
        const [obj] = this.gysData.filter(ele => ele.keyId === item)
        this.$set(data, 'signName', obj ? obj.enterpriseName : '')
      }
    },
    // 改变是否盖章
    changeIsSign (row, index, item, i) {
      const data = [{ finFileListInfoKeyId: row.keyId, isSignStatus: item.isSign, businessId: row.businessId, documentType: row.documentType, finFileListDetailKeyIds: [item.keyId] }]
      fileListApi.updateIsSignStatus(data).then(res => {
        this.checkboxchange(row, index)
      })
    },
    // 回显选中状态
    getChecked () {
      this.checkedCitiesL = []
      let allLength = 0
      if (this.tableData.length > 0) {
        this.tableData.forEach((item) => {
          item.fileListDetailVOS.forEach(ele => {
            allLength++
            if (ele.isSign === '1') {
              this.checkedCitiesL.push(item)
            }
          })
        })
        const checkedCount = this.checkedCitiesL.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < allLength
        this.checkAll = checkedCount === allLength
      }
    },
    // 表格行内多选框点击
    checkboxchange (val, index) {
      if (val === '1') {
        this.checkedCitiesL.push(this.tableData[index])
      } else {
        this.checkedCitiesL.pop()
      }
      const checkedCount = this.checkedCitiesL.length

      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length
      this.checkAll = checkedCount === this.tableData.length
      this.getChecked()
    },
    // 全选
    updateAllSelected (val) {
      this.checkAll = !this.checkAll
      this.checkedCitiesL = val
        ? JSON.parse(JSON.stringify(this.tableData))
        : []
      const newarr = this.tableData
      // 页面设置全选
      newarr.forEach((item, index) => {
        this.$set(newarr[index], 'isSign', val ? '1' : '0')
        item.fileListDetailVOS.forEach(ele => {
          this.$set(ele, 'isSign', val ? '1' : '0')
        })
      })
      const subData = newarr.map(item => {
        const ids = item.fileListDetailVOS.map(ele => ele.keyId)
        return { finFileListInfoKeyId: item.keyId, isSignStatus: item.isSign, businessId: item.businessId, documentType: item.documentType, finFileListDetailKeyIds: ids }
      })
      fileListApi.updateIsSignStatus(subData).then(res => {
        console.log(res.data)
      })
      this.getChecked()
    },
    // 预览模板文件
    previewShow (data) {
      this.$emit('previewShow', data)
    },
    // 删除文件
    deleteTemplate (row, type, index, i) {
      this.$confirm(type === 4 ? '是否删除当前项?' : '是否删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (row.addFile) { // 新增文件时候的删除
            this.tableData.splice(index, 1)
            this.tableData = this.filterData(
              this.tableData,
              'documentName',
              'documentNameRowSpan'
            )
          } else {
            // 构造参数
          // type:1 删除模板文件 2 删除已上传的合同文件 3 删除补充文件
            const data = {
              businessId: row.businessId || this.$route.query.businessId,
              documentId: row.documentId,
              keyId: row.keyId,
              keyIdF: row.keyIdF,
              documentType: row.documentType
            }
            this.deleteFile(type, data, index, row)
          }
        })
        .catch(() => {})
    },
    // 删除文件
    deleteFile (type, data, index, row) {
      const api = fileListApi
      if (type === 4) {
        if (row.addFiles) { // 初始化没有文件的时候删除
          api.deleteFileContracts([data.keyId]).then((res) => {
            if (res.data) {
              this.success('删除成功')
              this.$emit('getFilelist', 'start')
            } else {
              this.error('删除失败')
            }
          })
        } else { // 删除当前行文件项
          const fileList = this.tableData[index].fileListDetailVOS
          const ids = fileList.map(item => item.keyId)
          api.deleteFile(ids, '1').then((res) => {
            if (res.data) {
              this.success('删除成功')
              this.$emit('getFilelist', 'start')
            } else {
              this.error('删除失败')
            }
          })
        }
      } else if (type === 2) {
        api.deleteFile([data.keyIdF], '0').then((res) => {
          if (res.data) {
            this.success('删除成功')
            this.$emit('getFilelist', '')
          } else {
            this.error('删除失败')
          }
        })
      }
    },
    // 文件履历查看
    // 获取文件最新数据
    async getFileDetail (keyId) {
      return await fileListApi.fileDetailId({ detailId: keyId })
    },
    // 下载文件
    async dowload (data) {
      const detail = await this.getFileDetail(data.keyId)
      const fileId = (detail.data && detail.data.fileId) ? detail.data.fileId : data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    viewResume (row) {
      this.$emit('viewResume', row)
    },
    // 添加文件类型
    addFileType () {
      const data = {
        businessId: this.businessId,
        batchNum: Storage.getLocal('contractInfo').batchNum || null,
        documentType: '20' // 基础资料
      }
      // 判断是原始合同还是补签合同
      const api = this.type === 0 ? fileListApi.filelistList(data) : replenishApi.signNotdeploy(data)
      api.then((res) => {
        const arr = []
        res.data.forEach((ele) => {
          let state = true
          this.tableData.forEach((item) => {
          // 过滤已经存在的文件材料
            if (ele.documentId === item.documentId) {
              state = false
            }
          })
          if (state) {
            arr.push(ele)
          }
        })
        this.options = arr
        console.log(this.options, 'options')
        this.tableData.forEach((item) => {
          if (!item.option || item.option.length === 0) {
            this.$set(item, 'option', arr)
            // item.option = arr
          }
        })
      })
      this.tableData.push({
        documentName: '',
        documentId: '',
        addType: true,
        addFile: true,
        fileListDetailVOS: []
      })
      this.tableData = this.filterData(
        this.tableData,
        'documentName',
        'documentNameRowSpan'
      )
    },
    // 选择企业基础资料
    selectFile (index, documentId, enterpriseId) {
      this.documentId = documentId
      this.fileListDetailVOS = this.tableData[index].fileListDetailVOS || []
      this.enterpriseId = enterpriseId
      this.showIndex = index
      if (!this.documentId) {
        return this.warning('请先选择文件类型名称')
      } else if (!this.enterpriseId) {
        return this.warning('请先选择基础资料提供企业')
      }
      this.visible = true
    },
    // 选取文件数据
    selectFileList (data, index) {
      data.forEach(ele => {
        ele.fileListKeyId = this.tableData[index].keyId
      })
      const objs = this.tableData[index]
      const list = []
      data.forEach(ele => {
        const obj = {
          fileId: ele.fileId,
          fileName: ele.fileName,
          fileByte: ele.fileByte,
          documentId: objs.documentId,
          documentName: objs.documentName,
          businessId: this.businessId,
          documentType: objs.documentType,
          batchNum: Storage.getLocal('contractInfo').batchNum || null,
          signerId: objs.signerId,
          signed: ele.signed
        }
        list.push(obj)
      })
      if (list.length > 0) {
        fileListApi.UploadDetailLmplementFile(list).then((res) => {
          if (res.data) {
            this.success('文件上传成功')
            this.$emit('getFilelist', 'start')
          }
        })
      } else {
        // 选择的文件不存在时直接删除已经存在的文件
        const fileList = objs.fileListDetailVOS
        const ids = fileList.map(item => item.keyId)
        fileListApi.deleteFile(ids, 0).then((res) => {
          if (res.data) {
            this.success('删除成功')
            this.$emit('getFilelist', '')
          } else {
            this.error('删除失败')
          }
        })
      }
    },
    // 批量下载基础资料数据
    downAll () {
      const filtKeyIds = []
      this.tableData.forEach((item) => {
        if (item.fileListDetailVOS && item.fileListDetailVOS.length > 0) {
          item.fileListDetailVOS.forEach((elem) => {
            filtKeyIds.push(elem.currentFileId)
          })
        }
      })

      if (filtKeyIds.length > 0) {
        downAPi
          .batchDownload({ keyIds: filtKeyIds, fileName: '全部基础附件' })
          .then((res) => {
            downFile(res)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.addBtn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  overflow: hidden;
  color: #fff;
  .bg {
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 100;
    background: #ffb34d;
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
    transform: rotate(45deg);
  }
  .el-icon {
    position: absolute;
    top: 26px;
    right: 13px;
    z-index: 1000;
    transform: rotate(45deg);
  }
}
.isSign {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
