<template>
  <!-- 模板文件操作按钮组 -->
  <div class="fileButtonGroup">
    <span class="name" style="width:calc()">
      <div class="fileBox" v-if="showTag">
        <!-- <div class="flieStateIcon" v-if="!item.fileResource&&item.currentFileId">生成</div>
        <div class="flieStateIcon" v-if="item.fileResource&&item.currentFileId">上传</div> -->
        <div class="flieStateIcon" v-if="item.signed&&item.currentFileId">重盖</div>
      </div>
       <el-tooltip
            effect="dark"
            :content="
              item.fileName
            "
            placement="top"
            :disabled="disabledarr"
            :enterable="false"
          >
            <div class="fileName"  ref="valuebox">{{getFileName(item.fileName,'fileName')}}</div>
          </el-tooltip>
      <div class="suffix">{{getFileName(item.fileName,'suffix')}}</div> </span>
    <span class="content" v-if="item.fileId">
       <icon-button
        @click="onlineEdit(item, scope.$index, index)"
        content="在线编辑"
        icon="iconfont iconbanli"
        v-if="editBtn(item) && showEditBtn"
      />
      <icon-button
        content="预览"
        icon="iconfont iconyulan"
        @click="previewShow(item)"
        v-if="item.fileId !== '0'"
      />
       <icon-button
        @click="deleteTemplate(item, 2, scope.$index, index)"
        content="删除文件"
        icon="iconfont iconshanchu2"
        v-if="item.fileId !== '0'&&!canSubmitFlag&&scope.row.fileSource!=='3'"
      />
      <el-tooltip
        class=""
        effect="dark"
        :enterable="false"
        :content="item.fileId === '0' ? '上传' : '重新上传'"
        placement="top"
        v-if="scope.row.fileSource!=='3' && !noUpload"
      >
      <!-- accept=".doc,.docx,.xlsx,.xls,.pdf,.png,.jpg,.gif,.bmp" -->
        <base-button
          type="text"
          label=""
          icon="iconfont iconzhongxinshangchuan"
          btnType="upload"
          accept=".doc,.docx,.pdf"
          style="margin: 0px 10px"
          action="#"
          :http-request="(url)=> {
              return Upload(url, item, 2);
            }
          "
          v-if="item.fileId !== '0'&&scope.row.fileSource!=='3'"
        />
        <base-button
          type="text"
          label=""
          icon="iconfont iconshangchuan"
          btnType="upload"
          accept=".doc,.docx,.pdf"
          style="margin: 0px 10px"
          :http-request="(url)=> {
              return Upload(url, item, 2);
            }"
          action="#"
          v-else
        />
      </el-tooltip>
   <icon-button
        content="下载"
        icon="iconfont iconxiazai"
        @click="dowload(item)"
        v-if="item.fileId !== '0'"
      />
    </span>

  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { IconButton, BaseButton },
  props: {
    canSubmitFlag: {
      type: Boolean,
      default: false
    },
    scope: {},
    item: {},
    index: Number,
    noUpload: { // 不显示上传文件按钮
      type: Boolean,
      default: false
    },
    showEditBtn: { // 显示在线编辑按钮
      type: Boolean,
      default: true
    },
    showTag: { // 是否显示标签
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      disabledarr: true
    }
  },

  methods: {
    editBtn (item) {
      if (item.fileId !== '0' && (item.fileFileSuffix === 'doc' || item.fileFileSuffix === 'docx')) {
        return true
      } else {
        return false
      }
    },
    // 获取内容宽度
    getwidth () {
      this.dom = this.$refs.valuebox
      if (this.dom) {
        this.disabledarr = this.dom.offsetWidth >= this.dom.scrollWidth
        // this.dom.forEach((item, index) => {
        //   this.$set(
        //     this.disabledarr,
        //     index,
        //     item.offsetWidth >= item.scrollWidth
        //   )
        // })
      }
    },
    getFileName (fileName, type) {
      if (fileName) {
        const fileArr = fileName.split('.')
        this.$nextTick(() => {
          this.getwidth()
        })
        if (type === 'fileName') {
          fileArr.splice(fileArr.length - 1, 1)
          return fileArr.join()
        } else if (type === 'suffix') {
          return '.' + fileArr[fileArr.length - 1]
        }
      }
    },
    // 预览
    previewShow (row) {
      this.$emit('previewShow', row)
    },
    // 文件上传
    Upload (param, row, type) {
      this.$emit('Upload', param, { ...this.scope.row, ...row, keyId: this.scope.row.keyId, keyIdF: row.keyId }, type) // keyIdF 文件项KeyId
    },
    // 文件删除
    deleteTemplate (row, type, index, i) {
      this.$emit('deleteTemplate', { ...this.scope.row, ...row, keyId: this.scope.row.keyId, keyIdF: row.keyId }, type, index, i)
    },
    // 下载
    dowload (item) {
      this.$emit('dowload', item)
    },
    // 在线编辑
    onlineEdit (row, index, i) {
      this.$emit('onlineEdit', row, index, i)
    }
  }
}
</script>
<style lang="scss" scoped>
.fileName{
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
   max-width: calc(100% - 50px);
}
.suffix{
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fileButtonGroup {
  display: flex;
  align-items: center;
  .name {
    display: flex;
    align-items: center;
    text-align: left;
    width: calc(100% - 156px);
    // color: #FF834D;
    .fileBox{
      //  max-width: calc(100% - 35px);
      display: flex;
      .flieStateIcon{
        margin: 2px;
        width: 42px;
        height: 21px;
        border-radius: 4px;
        background-color: #FFF3DD ;
        text-align: center;
        line-height: 20px;
        color: #a39272;
      }
    }
  }
  .content {
    width: 156px;
  }
}
</style>
