<template>
  <!-- 标题统一样式组件 -->
  <div class="titleNameSlot">
    <img :src="img">
    <span class="title" :title="title"> <span style="color:#EE1C1C" v-if="required">*</span> {{title}}</span>
    <slot name="operation"></slot>
  </div>
</template>

<script>
import img from '@/assets/home/dato.png'
export default {
  name: 'titleNameSlot',
  props: {
    required: {
      type: Boolean,
      default: false
    },
    title: String
  },
  data () {
    return {
      img
    }
  }
}
</script>

<style lang="scss" src='./title-name-slot.scss' scoped></style>
